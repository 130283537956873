import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfBrand from "@/vue/domain/brand/df-brand";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import vuescroll from "vuescroll";

@Component({
  components: {
    DfPrivateArea: () => Utils.externalComponent2("df-private-area"),
    DfModal: () => import(/* webpackChunkName: 'df-modal' */ "@/vue/components/df-modal/df-modal.vue"),
    vuescroll,
  },
})
export default class DfHeaderMobileComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Boolean, default: true })
  showBrandLogoImageUrl!: boolean;
  @Prop({ type: Boolean, default: true })
  showBackButton!: boolean;

  get backIconImageUrl(): string {
    return `${Utils.getPublicPath()}/assets/back-icon.svg`;
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get headerPreviewStyle(): string {
    const style: any = {};
    if (this.selexLogoHeader) {
      style.backgroundImage = `url('${this.selexLogoHeader}')`;
    }

    return style;
  }

  get selexHomeUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_HOME_URL, "STRING");
  }

  get selexLogoHeader(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_SELEX_LOGO_HEADER, null);
  }

  get enablePrivateArea(): boolean {
    const rule01: boolean = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_PRIVATE_AREA_DISABLED, "BOOLEAN", false);
    const rule02: boolean = Utils.enablePrivateArea();
    return !rule01 && rule02;
  }

  get showLogin(): boolean {
    return this.$store.getters.showLogin;
  }

  get selexHeaderColor(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_HEADER_COLOR, "STRING", "black");
  }

  get selexLogoHeaderClass(): string {
    switch (Utils.getApiPath()) {
      case "alfi/gulliver":
        return "df-mr-12";
      default:
        return "";
    }
  }

  get selexLogoHeaderStyle(): string {
    switch (Utils.getApiPath()) {
      case "alfi/gulliver":
        return "width: 70%";
      default:
        return "";
    }
  }

  @Watch("showLogin")
  private showLoginChange() {
    if (this.showLogin) {
      this.$store.dispatch("setShowLogin", false);
      this.openPrivateAreaMobileModal();
    }
  }

  private openUrl(url: string) {
    window.open(url, "_self");
  }

  private goBack() {
    Utils.goBack(this);
  }

  private openPrivateAreaMobileModal() {
    this.$root.$emit("modules-modal.open", "df-modal.id.private-area-mobile-selex");
  }

  private closePrivateAreaMobileModal() {
    this.$root.$emit("modules-modal.close", "df-modal.id.private-area-mobile-selex");
  }
}
